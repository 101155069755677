import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/ket-qua-quay-thuong',
    name: 'KetQuaQuayThuong',
    component: () => import('../views/PrizeDrawResult.vue'),
  },
  {
    path: '/demo',
    name: 'demo',
    component: () => import('../components/LuckyDraw.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;

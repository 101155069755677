<template>
  <div class="container">
    <div class="main-container">
      <div class="side-left">
        <div></div>
        <div class="side-container">
          <h3 class="title">Thể lệ</h3>
          <div class="label">
            <span class="block mb-6">
              Mỗi khách hàng đăng ký học tại IELTS Mentor sẽ có mã học viên riêng và đó chính là mã dự thưởng tham gia
              chương trình quay thưởng.
            </span>
            <span class="block mb-6">
              Trung tâm tổ chức quay thưởng thưởng trúng số bằng phần mềm ngẫu nhiên theo lịch ở trên để xác định khách
              hàng đoạt giải thưởng.
            </span>
            <span class="block mb-6">
              <span class="block mb-3 label-title">HƯỚNG DẪN THAM GIA</span>
              <span class="block mb-1">Bước 1: Khách hàng đăng ký thành công học và có mã học viên để tham gia quay
                thưởng.</span>
              <span class="block mb-1">Bước 2: Khách hàng theo dõi Livestream tại Fanpage IELTS MENTOR vào 20h ngày
                25/8/2024.</span>
              <span class="block mb-1">Bước 3: Khách hàng để ý điện thoại theo SĐT đăng ký trên hợp đồng học tập để
                trung tâm liên hệ gửi quà.</span>
            </span>
            <span class="block mb-3">
              <span class="block mb-3 label-title">CƠ CẤU GIẢI THƯỞNG</span>
              <span class="block mb-1">- 01 GIẢI ĐẶC BIỆT: 01 Apple MacBook Air M1 256GB trị giá 18.490.000đ</span>
              <span class="block mb-1">- 02 GIẢI NHẤT: Mỗi giải 01 chỉ vàng SJC trị giá 7.850.000đ</span>
              <span class="block mb-1">- 05 GIẢI NHÌ: Mỗi giải 01 Máy tính bảng IvyPad Smart 5 trị giá 4.290.000đ</span>
              <span class="block mb-1">- 10 GIẢI BA: Mỗi giải 01 Đèn thông minh chữ G N69 trị giá 1.290.000đ</span>
              <span class="block mb-1">- HÀNG TRĂM PHẦN QUÀ MAY MẮN: Mỗi phần quà tương ứng 01 Tai nghe không dây ZTC
                Air
                A501 trị giá 790.000đ</span>
              Lưu ý: Toàn bộ khách hàng tham gia chương trình quay thưởng không trúng giải đều được nhận 01 phần quà may
              mắn.</span>
          </div>
        </div>
      </div>
      <div class="middle">
        <img src="~@/assets/images/sale_program.png" class="sale_program" alt="sale_program">
        <div class="flex justify-center relative mb-9">
          <img src="~@/assets/images/plane.png" class="plane absolute" alt="plane">
          <div class="button-title">
            LIVESTREAM
          </div>
        </div>
        <div class="title">
          PHẦN QUÀ
        </div>
        <div class="gift-slide relative">
          <img src="~@/assets/images/percent_left.png" class="percent_left absolute" alt="percent_left">
          <img src="~@/assets/images/percent_right.png" class="percent_right absolute" alt="percent_right">
          <swiper :slides-per-view="1" navigation :modules="[Navigation]" @slideChange="selectGift($event)">
            <swiper-slide v-for="(gift, index) in gifts" :key="index">
              <img :src="gift.image" class="gift-img" alt="gift-img">
              <span class="block gift-name mb-3">{{ gift.name }}</span>
              <span class="block gift-number">Số lượng quà còn lại: {{ gift.number }}</span>
            </swiper-slide>
          </swiper>
        </div>
        <LuckyDraw ref="luckyDraw" @random-user="randomUserCallBack" @gift-success="handleGiftSuccess" :gift_select="gift_select" :result="result_code"
          :maxSpins="6" />
      </div>
      <div class="side-right">
        <div class="side-container">
          <h3 class="title">Lịch sử quay thưởng</h3>
          <div class="mb-3" v-for="(log, index) in logs" :key="index">
            <div class="label mb-3">
              <span>Chúc mừng học viên </span>
              <span class="congrat-yell">{{ log.name }} - </span>
              <span class="congrat-yell">{{ log.code }}</span>
              <span> đã trúng thưởng </span>
              <span class="congrat-yell"> {{ log.gift_name }}</span>
            </div>
            <hr class="divider" />
          </div>
        </div>
        <div></div>
      </div>
    </div>
  </div>
  <div v-if="is_congrat" class="modal-overlay" @click="closeModal">
    <div class="modal-congratulation" @click.stop>
      <span class="block label">Chúc mừng học viên</span>
      <span class="block">{{ congrat_data.name }} - {{ congrat_data.code }}</span>
      <span class="block label">đã trúng thưởng </span>
      <span class="block">{{ congrat_data.gift_name }}</span>
    </div>
  </div>
</template>

<script>
import LuckyDraw from '../components/LuckyDraw.vue'

import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import axios from "axios";

export default {
  name: 'HomeView',
  components: {
    LuckyDraw,
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      Navigation
    };
  },
  data() {
    return {
      logs: [],
      gifts: [],
      is_congrat: false,
      gift_select: {},
      congrat_data: '',
      activeIndex: 0,
      result_code: 0
    }
  },
  mounted() {
    this.getRandomUser();
    this.getAllGift();
    this.getAllLogs();
  },
  methods: {
    randomUserCallBack(){
      this.getRandomUser();
    },
    getRandomUser() {
      const apiUrl = process.env.VUE_APP_API_URL;
      axios.get(`${apiUrl}/lucky-draw/users`).then(res => {
        this.result_code = Number(this.getRandomValue(res.data.data));
      });
    },
    getRandomValue(array) {
      const randomIndex = Math.floor(Math.random() * array.length);
      return array[randomIndex];
    },
    getAllLogs() {
      const apiUrl = process.env.VUE_APP_API_URL;
      axios
        .get(`${apiUrl}/lucky-draw`, {
          params: {
            is_export: true
          }
        })
        .then((res) => {
          this.logs = res.data.data;
        });
    },
    selectGift(event) {
      this.activeIndex = event.activeIndex;
      this.gift_select = this.gifts[event.activeIndex];
    },
    closeModal() {
      this.is_congrat = false;
      this.$refs.luckyDraw.confirmDraw();
    },
    async getAllGift() {
      const apiUrl = process.env.VUE_APP_API_URL;
      try {
        const res = await axios.get(`${apiUrl}/lucky-draw/gifts`);
        this.gifts = res.data.data;
        this.gift_select = this.gifts[this.activeIndex];
      } catch (error) {
        console.error("Error fetching gifts:", error);
      }
    },
    startSpinning() {
      this.$refs.luckyDraw.startSpinning();
    },
    async handleGiftSuccess(data) {
      this.is_congrat = true;
      this.congrat_data = data;
      this.getAllLogs();
      await this.getAllGift();
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/custom.scss';
</style>
